.outline {
  width: fit-content;
  padding: 3px;
  background-image: linear-gradient(144deg, #ffb9ba, transparent, #bfceec);
  margin: 0 auto;
  border-radius: 0.188rem;
}

nav.Navbar {
  box-shadow: 20px 20px 60px #cb9ea5, inset -20px -20px 60px #ffd6df;
  background-image: linear-gradient(144deg, rgba(255, 0, 0, 0), #9a92d7);
  padding: 0 2.5rem;
  margin: 0 auto;
}

.Navbar a {
  font-weight: 300;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.Navbar li,
.Navbar a,
.Navbar i {
  text-decoration: none;
  color: #dce3ff;
}

button.toggle-button {
  display: block;
  margin: 0 auto;
}

i.toggle-button {
  font-size: 1.5rem;
  padding-top: 0.2rem;
  text-align: center;
  color: transparent;
}

a.home-button {
  width: 5rem;
  padding-top: 0.8rem;
}

.Navbar div.home-button {
  background: url("./media/home.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  height: 2.2rem;
  margin: 0 auto;
  width: 2rem;
}

a.home-button:hover > div.home-button {
  background: url("./media/homehover.png");
  height: 2.2rem;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin: 0 auto;
}

.Navbar a:hover > * {
  color: #ffd6df;
  transition: all 1s ease;
}

.Navbar a:not(:hover) > * {
  transition: all 1s ease;
}

button.toggle-button:focus {
  outline: none !important;
  border: 3px solid #9c92c7;
  box-shadow: 0px 0px 0px #9c92c7;
}
button.toggle-button {
  border: none;
}

.Navbar i {
  font-size: 1.5rem;
  font-weight: 100;
  display: inline;
}

.Navbar p {
  font-size: 0;
  opacity: 0;
}

.Navbar a:hover > p {
  font-size: 1em;
  opacity: 1;
  display: block;
  margin: 0 auto;
  transition: all 1s ease;
}

.Navbar a:not(:hover) > p {
  transition: all 1s ease;
}

#navbarCollapse {
  transition: height 1.5s ease;
}
