.About {
  font-weight: 400;
  text-align: center;
  width: 100%;
}
.About p {
  font-weight: 350;
}

.profilepic-wrapper section.About {
  margin: 0 auto;
  width: 100%;
}

.About img {
  width: 100%;
  border-radius: 1rem;
}

@media (min-width: 480px) {
  .About .workspace-image-container {
    width: 65%;
  }
  .About .skill {
    text-align: start;
  }
  .About .education {
    text-align: end;
  }
  .About .desk-img,
  .About .workspace-image-container,
  .workspace-img,
  .workspace-img:after {
    border-radius: 1.5rem;
  }
  .About .workspace-outline,
  .workspace-note {
    border-radius: 25px;
    width: fit-content;
    padding: 0.15em 1.3em;
    font-weight: 500;
  }
}

div.intro {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.intro div {
  text-align: center;
}

.intro {
  margin: 0 auto;
  display: block;
  text-align: center;
}

.profilepic-wrapper {
  display: inline-block;
  position: relative;
  text-align: center;
}

.profile-img {
  width: 50%;
  max-width: 50%;
  margin: 0 auto;
}

img.profile-img-logo {
  position: absolute;
  top: 0%;
  right: 25%;
  width: 80%;
  max-width: 50%;
  box-shadow: none;
  background: #bebdf1f8;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

img.profile-img-logo:hover {
  animation: fadeOut 1s;
}

.reveal {
  opacity: 1;
}

.reveal.active-now {
  opacity: 0;
  animation: fadeOut 1s;
}

.reveal.seen {
  opacity: 1;
  animation: fadeIn 1.5s;
}

img.profile-img {
  width: 50%;
}

.About .desk-img,
.About .workspace-image-container,
.workspace-img,
.workspace-img:after {
  border: 0;
}

.About .workspace-outline {
  width: fit-content;
  padding: 3px;
  background-image: linear-gradient(144deg, #f0cfd0, #bfceec);
  margin: 0 auto 2em;
}

.About .workspace-note {
  background-color: transparent;
  border-radius: 1.5rem;
  color: #8585d4;
  display: block;
  font-size: 1.3rem;
  margin: 0 auto;
  padding: 0.15em 1.3em;
  text-decoration: none;
}

.About .workspace-image-container {
  margin: 0 auto;
  padding: 0%;
}

.About div.worskpace {
  position: relative;
  padding: 0;
}

.workspace-img {
  position: relative;
  width: 100%;
}

.workspace-img:after {
  position: absolute;
  content: " ";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(144deg, #bfceec22, #bfceec22);
}

div.workspace-img:hover:after {
  cursor: url("./media/crosscursor.png"), auto;
}

.workspace-btn {
  background-color: transparent;
  border: none;
  padding: 3px;
}

ul,
p {
  font-size: 1.3em;

  font-weight: 350;
  color: #2f3994;
}
ul {
  text-align: left;
}
.workspace-btn:hover {
  cursor: url("./media/cursor.png"), auto;
}

h4.workspace-title {
  padding-bottom: 0rem;
}

.About h5 {
  padding-bottom: 0.1rem;
}

.About ul {
  list-style-type: none;
  font-weight: 300;
  display: block;
  padding: 0;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

div.language-education {
  padding: 0rem 3rem;
}

.description p {
  text-align: left;
  padding: 0 3rem;
  margin-bottom: 0;
  width: 100%;
  /* display: flex-end; */
}

.workspace-item {
  color: #2f3994;
}

.About .skill {
  text-align: center;
}

.About .education {
  text-align: center;
}

.popover {
  padding: 0.5rem;
  border: none;
  background-image: linear-gradient(144deg, #e3c7de, #e3c7de);
  box-shadow: 20px 20px 60px #cb9ea599, inset -20px -20px 60px #ffd6df41;
  background-color: #ffd6df;
  width: 50%;
  transition: all 1s ease;
}

.workspace > * {
  transition: all 1s ease;
}

.popover p {
  text-align: center;
  color: #2f3994;
  font-size: 1em;
  font-weight: 300;
}

.popover h6 {
  font-weight: 400;
  padding-bottom: 0;
  color: #2f399483;
}

.popover-body {
  text-align: center;
  border: none;
}
