.web-project {
  position: relative;
  margin: 2% auto;
  display: block;
  width: 98%;
  height: 100%;
  padding-bottom: 2rem;
  font-size: 0;
  box-sizing: border-box;
  padding: 0.5rem;
  transition: transform 0.8s;
  z-index: auto;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.web-project:hover {
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.05);
  z-index: 99;
}

.project-image {
  width: 100%;
  display: block;
  border-radius: 1.5rem;
  height: 100%;
  border: none;
  opacity: 0.95;
}

.overlay {
  align-items: center;
  background-color: rgba(156, 146, 199, 0.6);
  background-image: linear-gradient(144deg, rgba(255, 0, 0, 0), #9a92d7);
  border-radius: 1.5rem;
  color: #2f3994;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-items: center;
  left: 0;
  opacity: 0;
  padding: 0.5em 2%;
  position: absolute;
  top: 0;
  width: 100%;
  transition: opacity 0.3s;
}

.overlay-blur {
  backdrop-filter: blur(5px);
}

.overlay > * {
  transform: translateY(20px);
  transition: transform 0.3s;
}

.overlay:hover {
  opacity: 1;
}

.overlay:hover > * {
  transform: translateY(0);
}

div.title,
p.project-description,
a.link-btn {
  color: antiquewhite;
  padding: 1rem;
  font-weight: 200;
  overflow: scroll;
}

div.title {
  color: antiquewhite;
  font-size: 3vh;
  font-weight: 300;
  padding: 2rem 0.5rem 0rem;
  text-transform: uppercase;
}

p.project-description {
  font-size: 2.3vh;
  margin-bottom: 0;
}

a.link-btn {
  background-image: linear-gradient(144deg, #ffb9ba55, transparent, #bfceec55);
  background-color: #9a92cd;
  font-size: 12px;
  padding: 0.3rem;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

a.link-btn:hover {
  color: #2f3994;
}

.link-btn p {
  background: #9c92c7;
  background-image: linear-gradient(40deg, #ffd6df33, #bfceec33);
  border-radius: 0.188rem;
  color: antiquewhite;
  font-weight: 300;
  margin-bottom: 0;
  padding: 0.5rem;
}

@media only screen and (max-width: 600px) {
  .web-project {
    margin-bottom: 3rem;
  }
  div.title,
  p.project-description,
  a.link-btn {
    font-size: 0.8rem;
    font-weight: 400;
  }
  a.link-btn p {
    font-weight: 400;
    font-size: 0.8rem;
  }
}
