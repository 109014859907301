.Contact {
  text-align: center;
  color: #2f3994;
  
}

.Contact .form-control {
  background-color: transparent;
  border-color: #2f3994;
  color: #2f3994;
  display: block;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  margin: 0 auto;
  max-width: 70%;
  text-transform: uppercase;
}

.Contact .form-control::placeholder {
  color: #2f3994;
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
}

.Contact .form-control:focus {
  background-color: transparent;
  border-color: rgba(255, 192, 203, 0.813);
  box-shadow: 0 0 10px pink;
  color: #2f3994;
  outline: none !important;
}

.Contact input.form-control,
.Contact textarea.form-control {
  color: #2f3994;
}

.Contact textarea.form-control {
  font-size: large;
  text-transform: none;
}

.Contact .col {
  margin-bottom: 1rem;
}

.Contact button.submit-btn {
  background-color: transparent;
  background-image: linear-gradient(144deg, #ffb9ba, transparent, #bfceec);
  border: 0;
  border-radius: 0.1rem;
  display: inline;
  padding: 0.2rem;
}

.Contact button.submit-btn:focus {
  outline: none !important;
  border: 4.5px solid #9c92c7;
  box-shadow: 0px 0px 0px #9c92c7;
}

.Contact .submit-btn h5 {
  color: #9a92d7;
  margin-bottom: 0;
  padding: 0;
  margin: 0;
  padding: 0.4rem 1rem;
  box-shadow: 20px 20px 60px #cb9ea5, inset -20px -20px 60px #ffd6df;
  background-image: linear-gradient(144deg, rgba(255, 0, 0, 0), #9a92d7);
  font-weight: 300;
  font-size: 1rem;
}

.Contact .submit-btn h5:hover {
  color: #3a439b;
}

.Contact .contact-options {
  text-align: start;
  margin: 0rem 2rem;
}

.Contact .contact-options a {
  color: #858bc2;
  font-size: 1.5em;
  padding: 0rem 0.5rem;
}

.Contact .sent-note img {
  height: 80%;
  width: 30%;
}

.Contact h4.message-sent {
  color: #333;
  margin-bottom: 0;
  text-transform: none;
}
