#App {
  overflow: -moz-scrollbars-none;
}

#App {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
}

.container {
  margin: auto;
  padding: auto;
}

.fade-in {
  animation: fadeIn 3s;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.App {
  background-image: linear-gradient(#abcdef83, #abcdef83),
    url(./media/background.png);
  background-position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  font-family: "Josefin Sans", sans-serif;
}

.App .center {
  margin: 0 auto;
  padding: 0 auto;
}

.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6 {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 350;
  color: #2f3994;
}

.App-header h1 {
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 0;
  font-weight: 300;
  background-clip: text;
  font-size: 2.5rem;
  color: transparent;
  padding-top: 1rem;
  background-image: linear-gradient(144deg, #ffb9ba, transparent, #bfceec);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 3px 3px;
}

.App h5 {
  font-weight: 300;
}

.App button {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.App-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  text-align: center;
}

.App .header-p {
  color: whitesmoke;
  display: block;
  font-size: 1.5em;
  opacity: 0.7;
  padding: 0 1rem;
  width: 100vw;
}

.App-logo {
  height: 30vmin;
}

.App .text-content-lavendar {
  background: #bebdf1;
  padding: 2rem 0rem;
}

.App-link {
  color: #1b2054;
  text-decoration: none;
  pointer-events: auto;
}

.App footer {
  color: #ffd6df;
  font-size: smaller;
  font-weight: 300;
  padding: 0.5%;
  margin-top: 0.7rem;
  text-align: center;
}

.App footer p {
  color: #2f3994;
}
