* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scrollbar-width: none;
}

html,
body {
    overflow: -moz-scrollbars-none;
    margin: 0;
    padding: 0;
    min-height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 300;
    color: #858BC2;
}

p {
    font-weight: 300;
    font-size: 1.2em;
    color: #2f3994;
}

section {
    width: 100%;
    background: #BEBDF1;
    padding: 2rem 0rem;
}