.Work {
  text-align: center;
  padding-bottom: 5vw;
  width: 100%;
  margin: 0 auto;
}

.Work h5 {
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.Work .web,
.Work .logofolio {
  margin: 0 auto 3rem;
  padding: 1rem 1.8rem 0;
}

.Work .logofolio img {
  width: 100%;
  border-radius: 1.5rem;
}

.outline-purple {
  width: fit-content;
  padding: 3px;
  margin: 0 auto;
  border-radius: 0.188rem;
}

.Work .logofolio-title {
  margin: 2rem 0 1rem;
}

.Work .logo-e {
  opacity: 0.95;
  margin: 0% auto 2%;
  position: relative;
  box-sizing: border-box;
  transition: transform 0.8s;
}

.Work .logo-e:hover {
  opacity: 1;
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.05);
}

.Work .logo-container {
  position: relative;
  margin: 2% auto;
  padding: 0.5rem;
  width: 80%;
}
