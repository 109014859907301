.Skill div {
  display: block;
  margin: 0 auto;
  width: 100%;
  margin: 0;
  text-align: left;
}

.Skill p {
  background-color: none;
  border: 0.04rem solid #858bc2;
  border-radius: 30px;
  display: inline-block;
  font-size: 1rem;
  margin: 0.8rem;
  text-align: center;
  padding: 0.5rem 1rem;
  width: fit-content;
}
